import React,{useState} from 'react'
import zeroduelogo2 from '../../Assets/zerodue-logo2.png'
import mailicon from '../../Assets/mailicon.png'
import callicon from '../../Assets/callicon.png'
import '../../css/Footer.scss';
import { Link ,useNavigate} from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from "react-icons/fa";
export default function Footer() {
    const navigate = useNavigate();
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
        <>
            <div className="Footer">
                <div className="container-fluid">
                    <div className="innerFooter">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 mt-4">
                                <div className="col-inside">
                                    <Link to={{ pathname: "/" }} onClick={scrollToTop}>
                                    <img className='LOGO' src={zeroduelogo2} width={"100%"} alt="zerodueLogo" style={{cursor:'pointer'}} />
                                    </Link>
                                    {/* <h5>It is a long established fact that from
                                        will be distracted by the readable from when looking.</h5> */}
                                    <div className="contactdetail mt-5">
                                        <img className="mailicon me-3" src={mailicon} alt="MailIcon" width={'10%'} />
                                        <h4>zerodue@gmail.com</h4>
                                    </div>
                                    <div className="contactdetail mt-4">
                                        <div className="imgs">
                                            <img src={callicon} alt="callIcon" width={'55%'} className="callicon me-3" />
                                        </div>
                                        <div className="texts">
                                            <h4>+987 6541 3654</h4>
                                            <h4>+001 6547 6589</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 mt-4">
                                <div className="col-page-inside">
                                    <h3>Pages</h3>
                                    <ul>
                                        <li><Link to={{ pathname: "/" }} onClick={scrollToTop}>Home</Link></li>
                                        <li><Link to={{pathname: '/about'}} onClick={scrollToTop}>About Us</Link></li>
                                        <li><Link to={{pathname: '/offerings'}} onClick={scrollToTop}>Offerings</Link></li>
                                        <li><Link to={{pathname: '/solutions'}} onClick={scrollToTop}>Solutions</Link></li>
                                        <li><Link to={{pathname: '/cmssolution'}} onClick={scrollToTop}>CMS Solution</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 mt-4">
                                <div className="col-page-inside">
                                    <h3>Utility Pages</h3>
                                    <ul>
                                        <li><Link to={{pathname: '/privacypolicy'}} onClick={scrollToTop}>Privacy Policy</Link></li>
                                        <li><Link to={{pathname: '/termscondition'}} onClick={scrollToTop}>Terms & Condition</Link></li>
                                        <li><Link to={{pathname: '/contactus'}} onClick={scrollToTop}>Contact Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 mt-4">
                                <div className="box">
                                    <h5>Links</h5>
                                    <h3 className='mt-3'>Social Media Links</h3>
                                    {/* <h4>It is a long established fact that a reader will be distracted layout.</h4> */}
                                    <div className="Links">
                                        <a  className="Link">
                                            <FaFacebookF />
                                        </a>
                                        <a className="Link">
                                            <FaTwitter />
                                        </a>
                                        <a  className="Link">
                                            <FaLinkedinIn />
                                        </a>
                                        <a  className="Link">
                                            <FaInstagram />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='footer-bottom'>
                        <h5>Copyright © Zerodue</h5>
                    </div>
                </div>
            </div>
        </>
    )
}

import React from 'react';

export const Homepage = React.lazy(() => import('../components/MainPages/HomePage'));
export const About = React.lazy(() => import('../components/MainPages/About'));
export const Error = React.lazy(() => import('../components/MainPages/ErrorPage'));
export const Solution = React.lazy(() => import('../components/MainPages/Solution'));
export const CMSsolution = React.lazy(() => import('../components/MainPages/CMSSolution'));
export const Offerings = React.lazy(() => import('../components/MainPages/Offering'));
export const PrivacyPolicy = React.lazy(() => import('../components/MainPages/PrivacyPolicy'));
export const TermsCondition = React.lazy(() => import('../components/MainPages/TermsCondition'));
export const ContactUs = React.lazy(() => import('../components/MainPages/ContactUS'));
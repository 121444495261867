const routes = {
    Homepage: '/',
    About: '/about',
    Error: '/error',
    Solution: '/solutions',
    CMSsolution: '/cmssolution',
    Offerings: '/offerings',
    PrivacyPolicy: '/privacypolicy',
    TermsCondition: '/termscondition',
    ContactUs: '/contactus',

}
export default routes;
import React, { useEffect, useRef, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import zerodueLogo from '../../Assets/zerodue-logo.png'
import logopart1 from '../../Assets/logopart1.png'
import logopart2 from '../../Assets/logopart2.png'
import { useNavigate } from "react-router-dom";
import '../../css/navbar.scss'
export default function Navbar() {
    let navRef = useRef(null);
    let navigate = useNavigate();
    const [screenSize, setScreenSize] = useState('small');
    useEffect(() => {
        let width = window.screen.width;
        if (width <= 991) {
            setScreenSize('small');
        } else {
            setScreenSize('large');
        }
    }, [])

    function navClickHandler() {
        if (screenSize === 'small') {
            if (navRef) {
                navRef.current.classList.toggle("opened")
            }
        }
    }
    window.scrollToSection = (sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
            sectionElement.scrollIntoView({
                behavior: 'smooth',
            });
        }
    };
    function scrollToSection(sectionId) {

        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    }
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
  return (
    <>
          <nav ref={navRef} className="navbar navbar-light navbar-expand-lg fixed-top">
            <div className="container">

                <Link className="navbar-brand updated-logo" to={{ pathname: "/" }} onClick={scrollToTop}>
                    <div  style={{position:'relative',textAlign:'center'}}>
                    <img src={logopart1} alt="Logo" width="60" height="auto" />
                    <img src={logopart2} className="logo2-image" alt="Logo" width="75" height="auto" style={{position:'absolute',top:'11%',left:'18%'}} />
                    </div>
                    <h5 className='logoText mt-2'>Zero<span>Due</span></h5>
                </Link>
                {/* <Link className="navbar-brand" to={{ pathname: "/" }} onClick={scrollToTop}>
                    <img src={zerodueLogo} alt="Logo" width="98" height="auto" />
                </Link> */}
                <button onClick={navClickHandler} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav m-auto mb-2 mb-lg-0" >
                        <li className="nav-item">
                            <Link className="nav-link" to={{ pathname: "/" }} onClick={scrollToTop}>Home</Link>
                            {/* <Link className="nav-link" href="#" onClick={() => scrollToSection('WWO')}>Home</Link> */}
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to={{pathname: '/about'}} onClick={scrollToTop} >About Us</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to={{pathname: '/offerings'}} onClick={scrollToTop} >Offerings</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to={{pathname: '/solutions'}} onClick={scrollToTop} >Solutions</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link ps-0" to={{pathname: '/cmssolution'}} onClick={scrollToTop}>CMS Solution</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link ps-0" to={{pathname: '/contactus'}} onClick={scrollToTop}>Contact Us</Link>
                        </li>
                    </ul>
                    {/* <button className='btn bg-light pt-2 pb-2'>Apply Now</button> */}
                    <a className="btn  pt-2 pb-2">
                        Connect Now
                    </a>
                </div>
            </div>
        </nav>
    </>
  )
}
